@import url('https://fonts.googleapis.com/css?family=EB+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css?family=Sirin+Stencil&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* color palette selection for background
https://coolors.co/c00149-dba964-10edfb-66c6da-fcf8d2 */

.App-link {
  color: #61dafb;
}


.overflowHidden {
  overflow-y: hidden;
  overflow-x: hidden;
}

video {
  object-fit: cover;
}

.navBarText {
  /* display: inline-block; */
  padding:4px 8px;
  font-size: 14px;
  font-weight: 500;
}

.repoButton{
  /* display: inline-block; */
  /* padding:4px 8px; */
  /* font-size: 17px; */
  padding-top: 1px;
  padding-left: 20px;
  font-weight: bold;
}

.repoButton:hover {
  /* color: #f48fb1; */
  /* font-size: 17px; */
  /* font-weight: bold; */
  text-shadow: 1px 0 0 currentColor;

}

.navBarText:hover {
  /* color: #f48fb1; */
  /* font-size: 17px; */
  /* font-weight: bold; */
  text-shadow: 1px 0 0 currentColor;

}

.navBarTextMobile {
  /* display: inline-block; */
  padding:0px 0px;
  font-size: 11px;
  font-weight: 500;
}

.navBarTextMobile:hover {
  /* color: #f48fb1; */
  /* font-size: 17px; */
  /* font-weight: bold; */
  text-shadow: 1px 0 0 currentColor;

}

/* .navBarText::after{
font-weight: bold;
overflow: hidden;
display: block;
visibility: hidden;
height: 0;
} */

.resumeDiv {
height: 45px;
display: flex;
vertical-align: middle;
color: whitesmoke;
}

.downloadResume {
height: 30px;
display: flex;
vertical-align: middle;
color: whitesmoke;
}

.resumeDiv:hover {
background-color: #66C6DA;
color: #FCF8D2;

}

.resumeDivMobile {
  height: 30px;
  display: flex;
  vertical-align: middle;
  color: whitesmoke;
  }

.responsive {
  width:100vw;
  height:100vh;
  opacity: 80%;
}  

/* body,html {
  margin: 0;
  padding: 0;
  overflow-x:hidden;
  overflow-y:hidden;
}
* {
  box-sizing: border-box;
} */

/* div {
  overflow-x:hidden;
  overflow-y:hidden;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
